import React, { useEffect, useState, useContext } from "react";
import Select, { StylesConfig } from "react-select";
import axios from "axios";
import { API_AUTH_URL } from "../../config";
import AuthContext from "../../context/AuthContext";
import chroma from "chroma-js";
import CalendarContext from "../../context/CalendarContext";
import MyButton from "../UI/button/MyButton";

const CalendarFilters = ({
  setEventsDates,
  eventsDates,
  closeFilters,
  moduleOptions,
  classOptions,
  eventTypesOptions,
}) => {
  const [module, setModule] = useState(() =>
    localStorage.getItem("moduleFilter")
      ? JSON.parse(localStorage.getItem("moduleFilter"))
      : []
  );
  const [classes, setClasses] = useState(() =>
    localStorage.getItem("classFilter")
      ? JSON.parse(localStorage.getItem("classFilter"))
      : []
  );
  const [eventTypes, setEventTypes] = useState(() =>
    localStorage.getItem("eventTypesFilter")
      ? JSON.parse(localStorage.getItem("eventTypesFilter"))
      : []
  );

  const setFullModule = (e) => {
    localStorage.setItem("moduleFilter", JSON.stringify(e));
    setModule(e);
  };

  const setFullClasses = (e) => {
    localStorage.setItem("classFilter", JSON.stringify(e));
    setClasses(e);
  };

  const setFullEventTypes = (e) => {
    localStorage.setItem("eventTypesFilter", JSON.stringify(e));
    setEventTypes(e);
  };

  const {
    authTokens,
    loading,
    setLoading,
    setGeneralError,
    updateToken,
    generalError,
    closeAlert,
    isRefreshingToken,
    setIsRefreshingToken,
  } = useContext(AuthContext);

  const { events, setEvents } = useContext(CalendarContext);

  const setNewFilters = () => {
    let paramsT = {
      module: module.map((item) => item.id),
      type: eventTypes.map((item) => item.id),
      klasses: classes.map((item) => item.id),
    };
    setEventsDates([eventsDates[0], eventsDates[1], paramsT]);
  }

  const setNewFiltersClear = () => {
    let paramsT = {

    }
    setEventsDates([eventsDates[0], eventsDates[1], {}]);
  }

  const setNewFiltersUser = () => {
    let paramsT = {
      user_related: true,
    }
    setEventsDates([eventsDates[0], eventsDates[1], paramsT]);
  }

  const clearFilters = async () => {
    await setModule([]);
    await setClasses([]);
    await setEventTypes([]);
    await localStorage.removeItem("moduleFilter");
    await localStorage.removeItem("classFilter");
    await localStorage.removeItem("eventTypesFilter");
    await setNewFiltersClear();
  };

  return (
    <div className="calendar-filters">
      <div className="detailed-closing-block">
        <i
          className="material-icons closing-icon filters-closing"
          onClick={closeFilters}
        >
          close
        </i>
      </div>
      <div className="filters-title">Фильтры</div>
      <div className="my-events-filter">
        <MyButton width="200px" onClick={() => setNewFiltersUser()}>
          Мои События
        </MyButton>
      </div>
      <div className="module-filter one-filter-block">
        <div className="one-filter-title">
          <div>По Модулям ВР</div>
          <div
            className="select-all-comment"
            onClick={() => setFullModule(moduleOptions)}
          >
            Выбрать все
          </div>
        </div>
        <Select
          placeholder={""}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              backgroundColor: state.isFocused ? "#ededed" : "#ededed",
              borderColor: state.isFocused ? "#813630" : "#813630",
              borderWidth: state.isFocused ? "2px" : "2px",
              borderRadius: state.isFocused ? "8px" : "8px",
              boxShadow: state.isFocused ? "none" : "none",
              fontSize: state.isFocused ? "14px" : "14px",
              "&:hover": {
                borderColor: state.isFocused ? "#813630" : "#813630",
              },
              cursor: state.isDisabled ? "not-allowed" : "default",
            }),
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
              const color = chroma(data.color_code);
              return {
                ...styles,
                backgroundColor: isDisabled
                  ? undefined
                  : isSelected
                  ? data.color_code
                  : isFocused
                  ? color.alpha(0.1).css()
                  : undefined,
                color: isDisabled
                  ? "#ccc"
                  : isSelected
                  ? chroma.contrast(color, "white") > 2
                    ? "white"
                    : "black"
                  : data.color_code,
                cursor: isDisabled ? "not-allowed" : "default",

                ":active": {
                  ...styles[":active"],
                  backgroundColor: !isDisabled
                    ? isSelected
                      ? data.color_code
                      : color.alpha(0.3).css()
                    : undefined,
                },
              };
            },
            input: (styles) => ({
              ...styles,
            }),
            placeholder: (styles) => ({
              ...styles,
            }),
            singleValue: (styles, { data }) => ({
              ...styles,
            }),
            multiValue: (styles, { data }) => {
              const color = chroma(data.color_code);
              return {
                ...styles,
                backgroundColor: color.alpha(0.1).css(),
              };
            },
            multiValueLabel: (styles, { data }) => ({
              ...styles,
              color: data.color_code,
            }),
            multiValueRemove: (styles, { data }) => ({
              ...styles,
              color: data.color_code,
              ":hover": {
                backgroundColor: data.color_code,
                color: "white",
              },
            }),
          }}
          options={moduleOptions}
          getOptionValue={({ id }) => id}
          getOptionLabel={({ name }) => name}
          value={module}
          defaultValue={moduleOptions}
          onChange={(e) => setFullModule(e)}
          isMulti
          closeMenuOnSelect={false}
        />
      </div>
      <div className="class-filter one-filter-block">
        <div className="one-filter-title">
          <div>По Классам</div>
          <div
            className="select-all-comment"
            onClick={() => setFullClasses(classOptions)}
          >
            Выбрать все
          </div>
        </div>
        <Select
          placeholder={""}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              backgroundColor: state.isFocused ? "#ededed" : "#ededed",
              borderColor: state.isFocused ? "#813630" : "#813630",
              borderWidth: state.isFocused ? "2px" : "2px",
              borderRadius: state.isFocused ? "8px" : "8px",
              boxShadow: state.isFocused ? "none" : "none",
              fontSize: state.isFocused ? "14px" : "14px",
              "&:hover": {
                borderColor: state.isFocused ? "#813630" : "#813630",
              },
            }),
            option: (provided, state) => ({
              ...provided,
              backgroundColor: state.isSelected ? "#813630" : "inherit",
              "&:hover": {
                backgroundColor: state.isSelected ? "#813630" : "#9f423b59",
              },
            }),
          }}
          options={classOptions}
          isMulti
          closeMenuOnSelect={false}
          getOptionValue={({ id }) => id}
          getOptionLabel={({ parallel, letter }) => parallel + letter}
          value={classes}
          onChange={(e) => setFullClasses(e)}
        />
      </div>
      <div className="external-filter one-filter-block">
        <div className="one-filter-title">
          <div>По Типам Событий</div>
          <div
            className="select-all-comment"
            onClick={() => setFullEventTypes(eventTypesOptions)}
          >
            Выбрать все
          </div>
        </div>
        <Select
          placeholder={""}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              backgroundColor: state.isFocused ? "#ededed" : "#ededed",
              borderColor: state.isFocused ? "#813630" : "#813630",
              borderWidth: state.isFocused ? "2px" : "2px",
              borderRadius: state.isFocused ? "8px" : "8px",
              boxShadow: state.isFocused ? "none" : "none",
              fontSize: state.isFocused ? "14px" : "14px",
              "&:hover": {
                borderColor: state.isFocused ? "#813630" : "#813630",
              },
            }),
            option: (provided, state) => ({
              ...provided,
              backgroundColor: state.isSelected ? "#813630" : "inherit",
              "&:hover": {
                backgroundColor: state.isSelected ? "#813630" : "#9f423b59",
              },
            }),
          }}
          options={eventTypesOptions}
          isMulti
          closeMenuOnSelect={false}
          getOptionValue={({ id }) => id}
          getOptionLabel={({ name }) => name}
          value={eventTypes}
          onChange={(e) => setFullEventTypes(e)}
        />
      </div>
      <div className="filters-submit">
        <MyButton onClick={() => setNewFilters()}>Применить</MyButton>
      </div>
      <div className="filters-clear" onClick={() => clearFilters()}>
        Сбросить фильтры
      </div>
    </div>
  );
};

export default CalendarFilters;
