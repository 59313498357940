import React, {useState, useEffect, useContext} from 'react';
import { getMonth } from '../../utils/getDates';
import Month from './Month';
import CalendarContext from '../../context/CalendarContext';


const MonthCalendar = ({setEventsDates, eventsDates, setCalendarType, dayClick, weekClick, makeDetailed, setEventToDetail}) => {
    const [currentMonth, setCurrentMonth] = useState(getMonth())
    const { monthIndex } = useContext(CalendarContext);

    useEffect(() => {
        setCurrentMonth(getMonth(monthIndex));
        // setEventsDates([currentMonth[0][0], currentMonth[currentMonth.length - 1][currentMonth[currentMonth.length - 1].length - 1]])
    }, [monthIndex]);

    useEffect(() => {
        setEventsDates([currentMonth[0][0], currentMonth[currentMonth.length - 1][currentMonth[currentMonth.length - 1].length - 1], eventsDates[2]])
    }, [currentMonth])

    // useEffect(() => {
    //     const fetchData = () => {
    //         let startDay = currentMonth[0][0]
    //         let endDay = currentMonth[currentMonth.length - 1][currentMonth[currentMonth.length - 1].length - 1]
    //         getEventsData(startDay, endDay)
    //     };
    //     if (true) {
    //         setLoading(true);
    //         fetchData();
    //         setLoading(false);
    //         isCalledRef.current = true;
    //     }
    // }, [monthIndex]);

    // useEffect(() => {
    //     let startDay = currentMonth[0][0]
    //     let endDay = currentMonth[currentMonth.length - 1][currentMonth[currentMonth.length - 1].length - 1]
    //     getEventsData(startDay, endDay)
    // }, [monthIndex, getEventsData])


    return (
        <React.Fragment>
                <Month month={currentMonth} monthIndex={monthIndex} setCalendarType={setCalendarType} dayClick={dayClick} weekClick={weekClick} makeDetailed={makeDetailed} setEventToDetail={setEventToDetail}/>
        </React.Fragment>
    );
}

export default MonthCalendar;
