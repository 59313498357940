import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useParams,
} from "react";
import Navbar from "../components/Navbar";
import MyButton from "../components/UI/button/MyButton";
import MyInputText from "../components/UI/input/MyInputText";
import { Calendar } from "primereact/calendar";
import AuthContext from "../context/AuthContext";
import axios from "axios";
import { API_AUTH_URL, ORGANIZATION_NAME } from "../config";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import {
  locale,
  addLocale,
  updateLocaleOption,
  updateLocaleOptions,
  localeOption,
  localeOptions,
} from "primereact/api";
import Select, { StylesConfig } from "react-select";
import chroma from "chroma-js";
import MyInputCheckbox from "../components/UI/input/MyInputCheckbox";
import MyTextarea from "../components/UI/textarea/MyTextarea";
import SuccessCreate from "../components/SuccessCreate";
import MyLoader from "../components/UI/loader/MyLoader";
import dayjs from "dayjs";
import MyToast from "../components/UI/toast/MyToast";
import SuccessEdit from "../components/SuccessEdit";

const PatchFormPage = () => {
  addLocale("ru", {
    firstDayOfWeek: 1,
    dayNames: [
      "воскресенье",
      "понедельник",
      "вторник",
      "среда",
      "четверг",
      "пятница",
      "суббота",
    ],
    dayNamesShort: ["вс", "пн", "вт", "ср", "чт", "пт", "сб"],
    dayNamesMin: ["вс", "пн", "вт", "ср", "чт", "пт", "сб"],
    monthNames: [
      "январь",
      "февраль",
      "март",
      "апрель",
      "май",
      "июнь",
      "июль",
      "август",
      "сентябрь",
      "октябрь",
      "ноябрь",
      "декабрь",
    ],
    monthNamesShort: [
      "янв",
      "фев",
      "мар",
      "апр",
      "май",
      "июн",
      "июл",
      "авг",
      "сен",
      "окт",
      "нояб",
      "дек",
    ],
    today: "Сегодня",
    clear: "Очистить",
    //...
  });
  locale("ru");
  var utc = require("dayjs/plugin/utc");
  var timezone = require("dayjs/plugin/timezone");
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const {
    authTokens,
    loading,
    setLoading,
    setGeneralError,
    updateToken,
    generalError,
    closeAlert,
    isRefreshingToken,
    setIsRefreshingToken,
  } = useContext(AuthContext);

  useEffect(() => {
    document.title = "Редактирование события | " + ORGANIZATION_NAME;
  }, []);

  const [eventTypeOptions, setEventTypeOptions] = useState([]);
  const [divisionOptions, setDivisionOptions] = useState([]);
  const [parallelOptions, setParallelOptions] = useState([
    { value: 1, label: "1 параллель" },
    { value: 2, label: "2 параллель" },
    { value: 3, label: "3 параллель" },
    { value: 4, label: "4 параллель" },
    { value: 5, label: "5 параллель" },
    { value: 6, label: "6 параллель" },
    { value: 7, label: "7 параллель" },
    { value: 8, label: "8 параллель" },
    { value: 9, label: "9 параллель" },
    { value: 10, label: "10 параллель" },
    { value: 11, label: "11 параллель" },
  ]);
  const [classOptions, setClassOptions] = useState([]);
  const [moduleOptions, setModuleOptions] = useState([]);
  const [organizerOptions, setOrganizerOptions] = useState([]);

  const [eventName, setEventName] = useState("");
  const [eventType, setEventType] = useState("");
  const [isExternal, setExternal] = useState(false);
  const [organizer, setOrganizer] = useState("");
  const [auditoryType, setAuditoryType] = useState(3);
  const [auditory, setAuditory] = useState([]);
  const [auditory2, setAuditory2] = useState([]);
  const [auditory3, setAuditory3] = useState([]);
  const [module, setModule] = useState("");
  const [prolongType, setProlongType] = useState(0);
  const [date, setDate] = useState("");
  const [timeStart, setTimeStart] = useState("");
  const [timeEnd, setTimeEnd] = useState("");
  const [place, setPlace] = useState("");
  const [comment, setComment] = useState("");
  const [linked, setLinked] = useState("");

  const [commentsList, setCommentsList] = useState([]);
  const [approvalList, setApprovalList] = useState([]);

  const [isApproval, setApproval] = useState(false);
  const [isTeacherBool, setTeacherBool] = useState(true);

  const [successCreate, setSuccessCreate] = useState(false);

  const [intersectionsError, setIntersectionsError] = useState("");

  const [eventId, setEventId] = useState(
    window.location.pathname.split("/")[2]
  );

  const InternalClick = () => {
    setExternal(false);
    setOrganizer("");
    if (internalRef) {
      const internalButton = internalRef.current;
      const externalButton = externalRef.current;
      externalButton.classList.remove("MyButton_active__asfJN");
      internalButton.classList.add("MyButton_active__asfJN");
    }
  };

  const ExternalClick = () => {
    setExternal(true);
    if (externalRef) {
      const externalButton = externalRef.current;
      const internalButton = internalRef.current;
      internalButton.classList.remove("MyButton_active__asfJN");
      externalButton.classList.add("MyButton_active__asfJN");
    }
  };

  const getDetailedEvent = async () => {
    setLoading(true);
    try {
      const res = await axios({
        url: `${API_AUTH_URL}/api/v1/events/${eventId}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": "ru",
          Authorization: `Bearer ${authTokens.access_token}`,
        },
      });
      console.log(res);
      setEventName(res.data.name);
      setEventType(res.data.type);
      setExternal(res.data.is_external);
      setOrganizer(res.data.organizer);
      setAuditory3(res.data.klasses);
      setModule(res.data.module);
      if (
        dayjs(res.data.date_start).format("YYYY-MM-DD") ===
        dayjs(res.data.date_end).format("YYYY-MM-DD")
      ) {
        setProlongType(0);
        // DayClick(oneDayRef, twoDayRef);
        setDate(new Date(dayjs(res.data.date_start)));
      } else {
        setProlongType(1);
        // DayClick(twoDayRef, oneDayRef);
        setDate([
          new Date(dayjs(res.data.date_start)),
          new Date(dayjs(res.data.date_end)),
        ]);
      }
      setTimeStart(new Date(dayjs(res.data.date_start)));
      setTimeEnd(new Date(dayjs(res.data.date_end)));
      setPlace(res.data.place);
      setComment(res.data.additional_info);
      setApproval(res.data.require_additional_approval);
      setLinked(res.data.linked_document);
      setCommentsList(
        res.data.comments.filter((item) => item.text !== "false")
      );
      setApprovalList(res.data.approval);
      setLoading(false);
    } catch (e) {
      console.log(e);
      if (e.response.status === 401 && !isRefreshingToken) {
        setLoading(true);

        setIsRefreshingToken(true); // Set flag to prevent recursion
        await updateToken();
        setIsRefreshingToken(false); // Reset flag after refresh
        await getDetailedEvent();
      } else if (e.response.data.detail) {
        setGeneralError([e.response.status, e.response.data.detail]);
      } else if (e.response.data.code) {
        setGeneralError([e.response.status, e.response.data.code]);
      } else if (e.response.data.non_filed_errors) {
        setGeneralError([
          e.response.status,
          e.response.data.non_filed_errors[0],
        ]);
      }
      setLoading(false);
    }
  };


  const getFormData = async () => {
    setLoading(true);
    try {
      const res = await axios({
        url: `${API_AUTH_URL}/api/v1/event_form/`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": "ru",
          Authorization: `Bearer ${authTokens.access_token}`,
        },
      });
      setDivisionOptions(res.data.building);
      setClassOptions(res.data.classes);
      setEventTypeOptions(res.data.event_types);
      setModuleOptions(res.data.modules);
      setOrganizerOptions(res.data.organizers);
      setTeacherBool(res.data.is_teacher);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      if (e.response.status === 401 && !isRefreshingToken) {
        setIsRefreshingToken(true); // Set flag to prevent recursion
        await updateToken();
        setIsRefreshingToken(false); // Reset flag after refresh
        await getFormData();
      } else if (e.response.data.detail) {
        setGeneralError([e.response.status, e.response.data.detail]);
      } else if (e.response.data.code) {
        setGeneralError([e.response.status, e.response.data.code]);
      } else if (e.response.data.non_filed_errors) {
        setGeneralError([
          e.response.status,
          e.response.data.non_filed_errors[0],
        ]);
      }
    }
  };


    const postCommentToEvent = async (eventId) => {
        try {
            const res = await axios({
                url: `${API_AUTH_URL}/api/v1/comments/`,
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept-Language": "ru",
                    Authorization: `Bearer ${authTokens.access_token}`,
                },
                data: {
                    text: comment === "" ? "false" : comment,
                    event: eventId,
                },
            });
            setSuccessCreate(true);
            setLoading(false);
        } catch (e) {
            console.log(e);


      if (e.response.status === 401 && !isRefreshingToken) {
        setIsRefreshingToken(true); // Set flag to prevent recursion
        await updateToken();
        setIsRefreshingToken(false); // Reset flag after refresh
        await postCommentToEvent();
      } else if (e.response.data.detail) {
        setGeneralError([e.response.status, e.response.data.detail]);
      } else if (e.response.data[0]) {
        setGeneralError([e.response.status, e.response.data[0]]);
      } else if (e.response.data.non_filed_errors) {
        setGeneralError([
          e.response.status,
          e.response.data.non_filed_errors[0],
        ]);
      }
      setLoading(false);
    }
  };

  const patchFormData = async () => {
    setLoading(true);

    let finalClasses = [];
    if (auditoryType === 0) {
      finalClasses = classOptions.map((item) => ({ id: item.id }));
    } else if (auditoryType === 1) {
      finalClasses = classOptions
        .filter((item) =>
          auditory.some((building) => item.building === building.id)
        )
        .map((item) => ({ id: item.id }));
    } else if (auditoryType === 2) {
      finalClasses = classOptions
        .filter((item) =>
          auditory2.some((parallel) => item.parallel === parallel.value)
        )
        .map((item) => ({ id: item.id }));
    } else if (auditoryType === 3) {
      finalClasses = auditory3.map((item) => ({ id: item.id }));
    }
    const finalClassesIds = finalClasses.map((item) => item.id);

    let startStamp = "";
    let endStamp = "";
    if (prolongType === 0) {
      const startStampDate = dayjs(date).format("YYYY-MM-DD");
      const startStampTime = dayjs(timeStart).format("HH:mm:00.000");
      const endStampTime = dayjs(timeEnd).format("HH:mm:00.000");
      startStamp = startStampDate + "T" + startStampTime + "+03:00";
      endStamp = startStampDate + "T" + endStampTime + "+03:00";
    } else if (prolongType === 1) {
      const startStampDate = dayjs(date[0]).format("YYYY-MM-DD");
      const endStampDate = dayjs(date[1]).format("YYYY-MM-DD");
      const startStampTime = dayjs(timeStart).format("HH:mm:00.000");
      const endStampTime = dayjs(timeEnd).format("HH:mm:00.000");
      startStamp = startStampDate + "T" + startStampTime + "+03:00";
      endStamp = endStampDate + "T" + endStampTime + "+03:00";
    }
    const finalStartStamp = startStamp;
    const finalEndStamp = endStamp;

    const data1 = {
      name: eventName,
      type: eventType.id,
      date_start: finalStartStamp,
      date_end: finalEndStamp,
      place: place,
      is_external: isExternal,
      require_additional_approval: isApproval,
      module: module.id,
      klasses: finalClassesIds,
      additional_info: comment,
      linked_document: linked,


      organizer: organizer.id,
    };

    const data2 = {
      name: eventName,
      type: eventType.id,
      date_start: finalStartStamp,
      date_end: finalEndStamp,
      place: place,
      is_external: isExternal,
      require_additional_approval: isApproval,
      module: module.id,
      klasses: finalClassesIds,
      additional_info: comment,
      linked_document: linked,
    };

    let datanew = null;

    if (isExternal) {
      datanew = data1;
    } else {
      datanew = data2;
    }

    try {
      const res = await axios({
        url: `${API_AUTH_URL}/api/v1/events/${eventId}/`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": "ru",
          Authorization: `Bearer ${authTokens.access_token}`,
        },
        data: datanew,
      });
      console.log(res);
      if (res.data.intersections) {
        setIntersectionsError(res.data.intersections);
      }
      // await postCommentToEvent(res.data.id);
      setSuccessCreate(true);
      setLoading(false);
    } catch (e) {
      console.log(e);
      if (e.response.status === 401 && !isRefreshingToken) {
        setIsRefreshingToken(true); // Set flag to prevent recursion
        await updateToken();
        setIsRefreshingToken(false); // Reset flag after refresh
        await patchFormData();
      } else if (e.response.data.detail) {
        setGeneralError([e.response.status, e.response.data.detail]);
      } else if (e.response.data.linked_document) {
        setGeneralError([e.response.status, e.response.data.linked_document]);
      } else if (e.response.data[0]) {
        setGeneralError([e.response.status, e.response.data[0]]);
      } else if (e.response.data.non_filed_errors) {
        setGeneralError([
          e.response.status,
          e.response.data.non_filed_errors[0],
        ]);
      }
    }
    setLoading(false);
  };

  const internalRef = useRef(null);
  const externalRef = useRef(null);

  const schoolRef = useRef(null);
  const divisionRef = useRef(null);
  const parallelRef = useRef(null);
  const classRef = useRef(null);

  const AuditoryClick = (thisButton, firstOther, secondOther, thirdOther) => {
    if (thisButton.current.id === "id1") {
      setAuditoryType(0);
    } else if (thisButton.current.id === "id2") {
      setAuditoryType(1);
    } else if (thisButton.current.id === "id3") {
      setAuditoryType(2);
    } else if (thisButton.current.id === "id4") {
      setAuditoryType(3);
    }
    const thisObject = thisButton.current;
    thisObject.classList.add("MyButton_active__asfJN");
    const firstObject1 = firstOther.current;
    firstObject1.classList.remove("MyButton_active__asfJN");
    const secondObject = secondOther.current;
    secondObject.classList.remove("MyButton_active__asfJN");
    const thirdObject = thirdOther.current;
    thirdObject.classList.remove("MyButton_active__asfJN");
  };

  const oneDayRef = useRef(null);
  const twoDayRef = useRef(null);

  const DayClick = (thisButton, otherButton) => {
    if (thisButton.current.id === "id1") {
      setProlongType(0);
    } else if (thisButton.current.id === "id2") {
      setProlongType(1);
    }
    const thisObject = thisButton.current;
    thisObject.classList.add("MyButton_active__asfJN");
    const firstObject1 = otherButton.current;
    firstObject1.classList.remove("MyButton_active__asfJN");
  };

  useEffect(() => {
    if (!loading) {
      const internalButton = internalRef.current;
      internalButton.classList.add("MyButton_active__asfJN");
      if (!isTeacherBool) {
        const thisObject = classRef.current;
        thisObject.classList.add("MyButton_active__asfJN");
      }
      const thisObject1 = oneDayRef.current;
      thisObject1.classList.add("MyButton_active__asfJN");
    }
  }, [loading]);

  useEffect(() => {
    const fetchData = async () => {
      await getDetailedEvent();
      await getFormData();
    };
    fetchData();
  }, []);

  useEffect(() => {
    setLoading(true);
    const body = document.getElementsByClassName("App")[0];
    body.classList.remove("lock");
  }, []);

  useEffect(() => {
    if (isExternal && !loading) {
      ExternalClick();
    } else if (!isExternal && !loading) {
      InternalClick();
    }
  }, [isExternal, loading]);

  useEffect(() => {
    if (prolongType === 0 && !loading) {
      DayClick(oneDayRef, twoDayRef);
    } else if (prolongType === 1 && !loading) {
      DayClick(twoDayRef, oneDayRef);
    }
  }, [prolongType, loading]);

  const dot = (color = "transparent") => ({
    alignItems: "center",
    display: "flex",
    ":before": {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: "block",
      marginRight: 8,
      height: 10,
      width: 10,
    },
  });

  useEffect(() => {
    if (isExternal) {
      setApproval(true);
    }
  }, [isExternal]);

  return (
    <div>
      <MyToast error={generalError} closeAlert={closeAlert} />
      <Navbar />
      <div className="main">
        {loading ? (
          <MyLoader />
        ) : (
          <div className="form-block">
            <div className="form-comments-block">
              <div className="form-one-item">
                <div className="form-item-title">Замечания и комментарии</div>
                <div className="approval-steps-title">Этапы согласования:</div>
                <div className="form-item-approval-steps">
                  {approvalList.map((item) => (
                    <div className="form-approval-one-item">
                      <div
                        className="approval-item-dot"
                        style={
                          item.status === -1
                            ? { "background-color": "#939397" }
                            : {
                                "background-color":
                                  item.status === 0 ? "#c30000" : "#00c100",
                              }
                        }
                      ></div>
                      <div className="approval-item-name">{item.role}</div>
                    </div>
                  ))}
                </div>
                <div className="approval-steps-title-2">
                  Комментарии согласующих:
                </div>
                <div className="form-item-comments-list">
                  {commentsList.length > 0 ? (
                    commentsList.map((item, index) => (
                      <div className="form-item-comment-one" key={item.id}>
                        {item.text === "false" ? (
                          ""
                        ) : (
                          <>
                            {index + 1}. {item.text} ({item.created_by})
                          </>
                        )}
                      </div>
                    ))
                  ) : (
                    <div className="form-item-text-no">
                      Пока комментариев нет
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="form-left-block form-one-block">
              <div className="external-internal-block form-one-item">
                <div className="form-item-title">Главное</div>
                <div className="form-item-text">Название мероприятия</div>
                <MyInputText
                  name="eventName"
                  type="text"
                  value={eventName}
                  onChange={(e) => setEventName(e.target.value)}
                />
                <div className="form-item-text">Тип мероприятия</div>
                <Select
                  placeholder={""}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      backgroundColor: state.isFocused ? "#e3e3e3" : "#e3e3e3",
                      borderColor: state.isFocused ? "#813630" : "#813630",
                      borderWidth: state.isFocused ? "2px" : "2px",
                      borderRadius: state.isFocused ? "8px" : "8px",
                      boxShadow: state.isFocused ? "none" : "none",
                      fontSize: state.isFocused ? "20px" : "20px",
                      "&:hover": {
                        borderColor: state.isFocused ? "#813630" : "#813630",
                      },
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected ? "#813630" : "inherit",
                      "&:hover": {
                        backgroundColor: state.isSelected
                          ? "#813630"
                          : "#9f423b59",
                      },
                    }),
                  }}
                  options={eventTypeOptions}
                  getOptionValue={({ id }) => id}
                  getOptionLabel={({ name }) => name}
                  value={eventType}
                  onChange={(e) => setEventType(e)}
                />
                <div className="form-item-text">
                      Организатор мероприятия:
                    </div>
                    <Select
                      placeholder={""}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          backgroundColor: state.isFocused
                            ? "#e3e3e3"
                            : "#e3e3e3",
                          borderColor: state.isFocused ? "#813630" : "#813630",
                          borderWidth: state.isFocused ? "2px" : "2px",
                          borderRadius: state.isFocused ? "8px" : "8px",
                          boxShadow: state.isFocused ? "none" : "none",
                          fontSize: state.isFocused ? "20px" : "20px",
                          "&:hover": {
                            borderColor: state.isFocused
                              ? "#813630"
                              : "#813630",
                          },
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isSelected
                            ? "#813630"
                            : "inherit",
                          "&:hover": {
                            backgroundColor: state.isSelected
                              ? "#813630"
                              : "#9f423b59",
                          },
                        }),
                      }}
                      options={organizerOptions}
                      getOptionValue={({ id }) => id}
                      getOptionLabel={({ name }) => name}
                      value={organizer}
                      onChange={(e) => setOrganizer(e)}
                    />
              </div>
              <div className="external-internal-block form-one-item">
                <div className="form-item-title">Ограничения</div>
                <div className="external-internal-buttons">
                  <MyButton
                    onClick={InternalClick}
                    innerRef={internalRef}
                    width="150px"
                  >
                    Внутреннее
                  </MyButton>
                  <MyButton
                    onClick={ExternalClick}
                    innerRef={externalRef}
                    width="150px"
                  >
                    Внешнее
                  </MyButton>
                </div>
                {isExternal ? (
                  <div className="date-text">
                    <MyInputCheckbox
                      naming={"Выездное (вне школы)"}
                      value={isApproval}
                      checked={isApproval}
                      onChange={(e) => setApproval(e.target.checked)}
                    />
                  </div>
                ) : (
                  <div className="date-text">
                    <MyInputCheckbox
                      naming={"Требуется согласование администрации"}
                      value={isApproval}
                      checked={isApproval}
                      onChange={(e) => setApproval(e.target.checked)}
                    />
                  </div>
                )}
              </div>
              <div className="audience-block form-one-item">
                <div className="form-item-title">Аудитория и участники</div>
                {!isTeacherBool && (
                  <div className="selection-buttons-block">
                    <MyButton
                      onClick={() =>
                        AuditoryClick(
                          schoolRef,
                          divisionRef,
                          parallelRef,
                          classRef
                        )
                      }
                      width="150px"
                      innerRef={schoolRef}
                      id="id1"
                    >
                      Школа
                    </MyButton>
                    <MyButton
                      onClick={() =>
                        AuditoryClick(
                          divisionRef,
                          schoolRef,
                          parallelRef,
                          classRef
                        )
                      }
                      width="150px"
                      innerRef={divisionRef}
                      id="id2"
                    >
                      Корпуса
                    </MyButton>
                    <MyButton
                      onClick={() =>
                        AuditoryClick(
                          parallelRef,
                          divisionRef,
                          schoolRef,
                          classRef
                        )
                      }
                      width="150px"
                      innerRef={parallelRef}
                      id="id3"
                    >
                      Параллели
                    </MyButton>
                    <MyButton
                      onClick={() =>
                        AuditoryClick(
                          classRef,
                          divisionRef,
                          parallelRef,
                          schoolRef
                        )
                      }
                      width="150px"
                      innerRef={classRef}
                      id="id4"
                    >
                      Классы
                    </MyButton>
                  </div>
                )}
                {auditoryType === 0 && <></>}
                {auditoryType === 1 && (
                  <Select
                    closeMenuOnSelect={false}
                    placeholder={""}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: state.isFocused
                          ? "#e3e3e3"
                          : "#e3e3e3",
                        borderColor: state.isFocused ? "#813630" : "#813630",
                        borderWidth: state.isFocused ? "2px" : "2px",
                        borderRadius: state.isFocused ? "8px" : "8px",
                        boxShadow: state.isFocused ? "none" : "none",
                        fontSize: state.isFocused ? "20px" : "20px",
                        "&:hover": {
                          borderColor: state.isFocused ? "#813630" : "#813630",
                        },
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected
                          ? "#813630"
                          : "inherit",
                        "&:hover": {
                          backgroundColor: state.isSelected
                            ? "#813630"
                            : "#9f423b59",
                        },
                      }),
                    }}
                    options={divisionOptions}
                    isMulti
                    getOptionValue={({ id }) => id}
                    getOptionLabel={({ name }) => name}
                    value={auditory}
                    onChange={(e) => setAuditory(e)}
                  />
                )}

                {auditoryType === 2 && (
                  <Select
                    closeMenuOnSelect={false}
                    placeholder={""}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: state.isFocused
                          ? "#e3e3e3"
                          : "#e3e3e3",
                        borderColor: state.isFocused ? "#813630" : "#813630",
                        borderWidth: state.isFocused ? "2px" : "2px",
                        borderRadius: state.isFocused ? "8px" : "8px",
                        boxShadow: state.isFocused ? "none" : "none",
                        fontSize: state.isFocused ? "20px" : "20px",
                        "&:hover": {
                          borderColor: state.isFocused ? "#813630" : "#813630",
                        },
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected
                          ? "#813630"
                          : "inherit",
                        "&:hover": {
                          backgroundColor: state.isSelected
                            ? "#813630"
                            : "#9f423b59",
                        },
                      }),
                    }}
                    options={parallelOptions}
                    isMulti
                    value={auditory2}
                    onChange={(e) => setAuditory2(e)}
                  />
                )}

                {auditoryType === 3 && (
                  <Select
                    closeMenuOnSelect={false}
                    placeholder={""}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: state.isFocused
                          ? "#e3e3e3"
                          : "#e3e3e3",
                        borderColor: state.isFocused ? "#813630" : "#813630",
                        borderWidth: state.isFocused ? "2px" : "2px",
                        borderRadius: state.isFocused ? "8px" : "8px",
                        boxShadow: state.isFocused ? "none" : "none",
                        fontSize: state.isFocused ? "20px" : "20px",
                        "&:hover": {
                          borderColor: state.isFocused ? "#813630" : "#813630",
                        },
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected
                          ? "#813630"
                          : "inherit",
                        "&:hover": {
                          backgroundColor: state.isSelected
                            ? "#813630"
                            : "#9f423b59",
                        },
                      }),
                    }}
                    options={classOptions}
                    isMulti
                    getOptionValue={({ id }) => id}
                    getOptionLabel={({ parallel, letter }) => parallel + letter}
                    value={auditory3}
                    onChange={(e) => setAuditory3(e)}
                  />
                )}
              </div>
              <div className="module-vr-block form-one-item">
                <div className="form-item-title">Модуль ВР</div>
                <div className="form-module-block">
                  <div className="date-text form-item-text">
                    Выберите модуль, нажав на поле ниже:
                  </div>
                  <Select
                    placeholder={""}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: state.isFocused
                          ? "#e3e3e3"
                          : "#e3e3e3",
                        borderColor: state.isFocused ? "#813630" : "#813630",
                        borderWidth: state.isFocused ? "2px" : "2px",
                        borderRadius: state.isFocused ? "8px" : "8px",
                        boxShadow: state.isFocused ? "none" : "none",
                        fontSize: state.isFocused ? "20px" : "20px",
                        "&:hover": {
                          borderColor: state.isFocused ? "#813630" : "#813630",
                        },
                      }),
                      option: (
                        styles,
                        { data, isDisabled, isFocused, isSelected }
                      ) => {
                        const color = chroma(data.color_code);
                        return {
                          ...styles,
                          backgroundColor: isDisabled
                            ? undefined
                            : isSelected
                            ? data.color_code
                            : isFocused
                            ? color.alpha(0.1).css()
                            : undefined,
                          color: isDisabled
                            ? "#ccc"
                            : isSelected
                            ? chroma.contrast(color, "white") > 2
                              ? "white"
                              : "black"
                            : data.color_code,
                          cursor: isDisabled ? "not-allowed" : "default",

                          ":active": {
                            ...styles[":active"],
                            backgroundColor: !isDisabled
                              ? isSelected
                                ? data.color_code
                                : color.alpha(0.3).css()
                              : undefined,
                          },
                        };
                      },
                      input: (styles) => ({
                        ...styles,
                        ...dot(),
                      }),
                      placeholder: (styles) => ({
                        ...styles,
                        ...dot("#ccc"),
                      }),
                      singleValue: (styles, { data }) => ({
                        ...styles,
                        ...dot(data.color_code),
                      }),
                    }}
                    options={moduleOptions}
                    getOptionValue={({ id }) => id}
                    getOptionLabel={({ name }) => name}
                    value={module}
                    onChange={(e) => setModule(e)}
                  />
                </div>
              </div>
            </div>
            <div className="form-right-block form-one-block">
              <div className="basic-data-block form-one-item">
                <div className="form-item-title">Общие сведения</div>
                <div className="external-internal-buttons">
                  <MyButton
                    onClick={() => DayClick(oneDayRef, twoDayRef)}
                    innerRef={oneDayRef}
                    id="id1"
                  >
                    Один день
                  </MyButton>
                  <MyButton
                    onClick={() => DayClick(twoDayRef, oneDayRef)}
                    innerRef={twoDayRef}
                    id="id2"
                  >
                    Многодневное
                  </MyButton>
                </div>
                {prolongType === 0 && (
                  <div className="form-date-block">
                    <div className="date-text">
                      Установите дату мероприятия, нажав на поле ниже:
                    </div>
                    <Calendar
                      value={date}
                      key={date}
                      onChange={(e) => setDate(e.target.value)}
                      locale="ru"
                      dateFormat="dd.mm.yy"
                      touchUI={window.innerWidth < 1024 ? true : false}
                    />
                  </div>
                )}
                {prolongType === 1 && (
                  <div className="form-date-block">
                    <div className="date-text">
                      Установите диапазон дат проведения мероприятия, нажав на
                      поле ниже:
                    </div>
                    <Calendar
                      value={date}
                      onChange={(e) => setDate(e.value)}
                      selectionMode="range"
                      locale="ru"
                      dateFormat="dd.mm.yy"
                      touchUI={window.innerWidth < 1024 ? true : false}
                    />
                  </div>
                )}

                <div className="date-text">
                  Установите время начала и окончания мероприятия:
                </div>
                <div className="form-time-block">
                  <Calendar
                    value={timeStart}
                    onChange={(e) => setTimeStart(e.value)}
                    timeOnly
                    touchUI={window.innerWidth < 1024 ? true : false}
                  />
                  <div className="date-text-arrow">
                    <i className="material-icons arrrow-flat-icon">
                      trending_flat
                    </i>
                  </div>
                  <Calendar
                    value={timeEnd}
                    onChange={(e) => setTimeEnd(e.value)}
                    timeOnly
                    touchUI={window.innerWidth < 1024 ? true : false}
                  />
                </div>
                <div className="form-place-block">
                  <div className="date-text">
                    Укажите место проведения мероприятия:
                  </div>
                  <MyInputText
                    type="text"
                    value={place}
                    onChange={(e) => setPlace(e.target.value)}
                  />
                </div>
                <div className="form-place-block">
                  <div className="date-text">
                    Добавьте ссылку на форму, облачное хранилище, файл, и тд:
                  </div>
                  <MyInputText
                    type="text"
                    value={linked}
                    onChange={(e) => setLinked(e.target.value)}
                  />
                </div>
                <div className="form-comment-block">
                  <div className="date-text">
                    Укажите дополнительные комментарии:
                  </div>
                  <MyTextarea
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  />
                </div>
              </div>
              <div className="submit-button-block">
                <MyButton
                  onClick={() => patchFormData()}
                  disabled={
                    eventName.length < 1 ||
                    eventType.length < 1 ||
                    module.length < 1 ||
                    (date != null && date != undefined && date.length < 1) ||
                    (timeStart != null &&
                      timeStart != undefined &&
                      timeStart.length < 1) ||
                    (timeEnd != null &&
                      timeEnd != undefined &&
                      timeEnd.length < 1) ||
                    place.length < 1 ||
                    (auditoryType === 1 && auditory.length < 1) ||
                    (auditoryType === 2 && auditory2.length < 1) ||
                    (auditoryType === 3 && auditory3.length < 1) ||
                    (isExternal && organizer.length < 1)
                  }
                >
                  Сохранить изменения
                </MyButton>
              </div>
            </div>
          </div>
        )}
      </div>
      {successCreate && (
        <SuccessEdit
          closeMessage={() => setSuccessCreate(false)}
          intersections={intersectionsError}
        />
      )}
    </div>
  );
};

export default PatchFormPage;
