import React, { useContext, useState, useEffect } from 'react';
import MyButton from "../UI/button/MyButton";
import { getWeek } from '../../utils/getDates';
import CalendarContext from '../../context/CalendarContext';
import Week from './Week';
import dayjs from 'dayjs';


const WeekCalendar = ({ setEventsDates, eventsDates, setCalendarType, dayClick, makeDetailed, setEventToDetail}) => {
    const [currenWeek, setCurrentWeek] = useState(getWeek())
    const { weekIndex, yearIndex } = useContext(CalendarContext)

    useEffect(() => {
        setCurrentWeek(getWeek(weekIndex, yearIndex));
    }, [weekIndex, yearIndex]);

    useEffect(() => {
        setEventsDates([dayjs(currenWeek[0][0]), dayjs(currenWeek[0][6]), eventsDates[2]])
    }, [currenWeek])

    return (
        <React.Fragment>
            <div className="month-calendar-body">
                <Week week={currenWeek} setCalendarType={setCalendarType} dayClick={dayClick} makeDetailed={makeDetailed} setEventToDetail={setEventToDetail}/>
            </div>
        </React.Fragment>
    );
}

export default WeekCalendar;
