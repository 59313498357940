import { useState, useRef, useEffect, useContext } from 'react';
import React from 'react';
import Navbar from '../components/Navbar';
import MyButton from "../components/UI/button/MyButton";
import DayCalendar from '../components/CalendarPage/dayCalendar';
import WeekCalendar from '../components/CalendarPage/weekCalendar';
import MonthCalendar from '../components/CalendarPage/monthCalendar';
import DetailedEvent from '../components/CalendarPage/DetailedEvent';
import CalendarContext from '../context/CalendarContext';
import dayjs from 'dayjs';
import MonthManager from '../components/CalendarPage/MonthManager';
import WeekManager from '../components/CalendarPage/WeekManager';
import DayManager from '../components/CalendarPage/DayManager';
import { eventsData } from '../utils/testData';
import CalendarFilters from '../components/CalendarPage/CalendarFilters';
import AuthContext from '../context/AuthContext';
import axios from 'axios';
import { API_AUTH_URL, ORGANIZATION_NAME } from '../config';
import MyLoader from '../components/UI/loader/MyLoader';
import MyToast from '../components/UI/toast/MyToast';


const CalendarPage = () => {
    const [isDetailed, setDetailed] = useState(0)
    const {calendarType, setCalendarType} = useContext(CalendarContext)
    const [eventToDetail, setEventToDetail] = useState('')
    const [eventsDates, setEventsDates] = useState([dayjs().subtract(2, 'month'), dayjs().add(2, 'month')])

    const {
      authTokens,
      loading,
      setLoading,
      setGeneralError,
      updateToken,
      generalError,
      closeAlert,
      isRefreshingToken,
      setIsRefreshingToken,
  } = useContext(AuthContext);

    const {monthIndex, setMonthIndex} = useContext(CalendarContext)
    const {weekIndex, setWeekIndex} = useContext(CalendarContext)
    const {dayIndex, setDayIndex} = useContext(CalendarContext)
    const {yearIndex, setYearIndex} = useContext(CalendarContext)
    const {events, setEvents} = useContext(CalendarContext)

    useEffect(() => {
      document.title = "Календарь | " + ORGANIZATION_NAME
    }, [])

    const handleToday = () => {
      setMonthIndex(dayjs().month())
      setWeekIndex(dayjs().week())
      setDayIndex(dayjs().date());
      setYearIndex(dayjs().year())
      getEventsData();
      setLoading(false);
  }

    const dayRef = useRef(null)
    const weekRef = useRef(null)
    const monthRef = useRef(null)

    const calendarRef = useRef(null)
    const detailedRef = useRef(null)
    const filtersRef = useRef(null)


    const dayClick = () => {
        setCalendarType(0)
        if (dayRef) {
          const dayButton = dayRef.current;
          const weekButton = weekRef.current;
          const monthButton = monthRef.current;
          dayButton.classList.add("MyButton_active__asfJN")
          weekButton.classList.remove("MyButton_active__asfJN")
          monthButton.classList.remove("MyButton_active__asfJN")
        }
      }

      const weekClick = () => {
        setCalendarType(1)
        if (weekRef) {
          const weekButton = weekRef.current;
          const dayButton = dayRef.current;
          const monthButton = monthRef.current;
          weekButton.classList.add("MyButton_active__asfJN")
          dayButton.classList.remove("MyButton_active__asfJN")
          monthButton.classList.remove("MyButton_active__asfJN")
          
        }
      }
    
      const monthClick = () => {
        setCalendarType(2)
        if (monthRef) {
          const monthButton = monthRef.current;
          const dayButton = dayRef.current;
          const weekButton = weekRef.current;
          monthButton.classList.add("MyButton_active__asfJN")
          dayButton.classList.remove("MyButton_active__asfJN")
          weekButton.classList.remove("MyButton_active__asfJN")
        }
      }

      const makeDetailed = () => {
        setDetailed(isDetailed + 1)
        const calendar = calendarRef.current
        // calendar.classList.add("narrow")
        // calendar.classList.remove("wide")
        const detailed = detailedRef.current
        detailed.classList.add("opened")
        detailed.classList.remove("closed")
      }

      const closeDetailed = () => {
        setDetailed(false)
        const calendar = calendarRef.current
        // calendar.classList.add("wide")
        // calendar.classList.remove("narrow")
        const detailed = detailedRef.current
        detailed.classList.add("closed")
        detailed.classList.remove("opened")
      }

      const openFilters = () => {
        const filters = filtersRef.current
        filters.classList.add("opened")
        filters.classList.remove("closed")
      }

      const closeFilters = () => {
        const filters = filtersRef.current
        filters.classList.add("closed")
        filters.classList.remove("opened")
      }

    useEffect(() => {
      if (!loading) {
      const dayButton = dayRef.current;
      const weekButton = weekRef.current;
      const monthButton = monthRef.current;
      if (calendarType === 0) {
        dayButton.classList.add("MyButton_active__asfJN")
      } else if (calendarType === 1) {
        weekButton.classList.add("MyButton_active__asfJN")
      } else if (calendarType === 2) {
        monthButton.classList.add("MyButton_active__asfJN")
      }
      weekButton.classList.add("d-w-m-button")
      dayButton.classList.add("d-w-m-button")
      monthButton.classList.add("d-w-m-button")
    }
    }, [loading, calendarType])

    const [moduleOptions, setModuleOptions] = useState([]);
    const [classOptions, setClassOptions] = useState([]);
    const [eventTypesOptions, setEventTypesOptions] = useState([]);

    const getEventsData = async (start = dayjs().subtract(2, 'month'), end = dayjs().add(2, 'month'), par = {}) => {
      setLoading(true);
      let actualParams = par;
      if (Object.keys(par).length === 1) {
        actualParams = {
          start: start.format('YYYY-MM-DD HH:mm'),
          end: end.format('YYYY-MM-DD HH:mm'),
          user_related: true,
        }
      } else if (Object.keys(par).length === 3) {
        actualParams = {
          start: start.format('YYYY-MM-DD HH:mm'),
          end: end.format('YYYY-MM-DD HH:mm'),
          module: par.module,
          type: par.type,
          klasses: par.klasses,
        }
      } else {
        actualParams = {
          start: start.format('YYYY-MM-DD HH:mm'),
          end: end.format('YYYY-MM-DD HH:mm'),
        }
      }
      
      try {
          const res = await axios({
              url: `${API_AUTH_URL}/api/v1/events/`,
              method: "GET",
              headers: {
                "Accept-Language": "ru",
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${authTokens.access_token}`,
              },
              params: actualParams
          });
          setEvents(res.data);
          setLoading(false)
      } catch (e) {
          console.log(e);
          if (e.response.status === 401 && !isRefreshingToken) {
              setIsRefreshingToken(true); // Set flag to prevent recursion
              await updateToken();
              setIsRefreshingToken(false); // Reset flag after refresh
              await getEventsData(start, end, par);
          } else if (e.response.data.detail) {
              setGeneralError([e.response.status, e.response.data.detail]);
          } else if (e.response.data.code) {
              setGeneralError([e.response.status, e.response.data.code]);
          } else if (e.response.data.non_filed_errors) {
              setGeneralError([
                  e.response.status,
                  e.response.data.non_filed_errors[0],
              ]);
          }
      }
  };



  const getModuleOptions = async () => {
    try {
        const res = await axios({
            url: `${API_AUTH_URL}/api/v1/modules/`,
            method: "GET",
            headers: {
                "Accept-Language": "ru",
                "Content-Type": "application/json",
                Authorization: `Bearer ${authTokens.access_token}`,
            },
        });
        setModuleOptions(res.data);
    } catch (e) {
        console.log(e);
        if (e.response.status === 401 && !isRefreshingToken) {
            await setIsRefreshingToken(true); // Set flag to prevent recursion
            await updateToken();
            await setIsRefreshingToken(false); // Reset flag after refresh
            await getModuleOptions();
        } else if (e.response.data.detail) {
            setGeneralError([e.response.status, e.response.data.detail]);
        } else if (e.response.data.code) {
            setGeneralError([e.response.status, e.response.data.code]);
        } else if (e.response.data.non_filed_errors) {
            setGeneralError([
                e.response.status,
                e.response.data.non_filed_errors[0],
            ]);
        }
    }
};

const getClassOptions = async () => {
  try {
      const res = await axios({
          url: `${API_AUTH_URL}/api/v1/classes/`,
          method: "GET",
          headers: {
            "Accept-Language": "ru",
              "Content-Type": "application/json",
              Authorization: `Bearer ${authTokens.access_token}`,
          },
      });
      setClassOptions(res.data);
  } catch (e) {
      console.log(e);
      if (e.response.status === 401 && !isRefreshingToken) {
          await setIsRefreshingToken(true); // Set flag to prevent recursion
          await updateToken();
          await setIsRefreshingToken(false); // Reset flag after refresh
          await getClassOptions();
      } else if (e.response.data.detail) {
          setGeneralError([e.response.status, e.response.data.detail]);
      } else if (e.response.data.code) {
          setGeneralError([e.response.status, e.response.data.code]);
      } else if (e.response.data.non_filed_errors) {
          setGeneralError([
              e.response.status,
              e.response.data.non_filed_errors[0],
          ]);
      }
  }
};

const getEventTypesOptions = async () => {
  try {
      const res = await axios({
          url: `${API_AUTH_URL}/api/v1/event_types/`,
          method: "GET",
          headers: {
            "Accept-Language": "ru",
              "Content-Type": "application/json",
              Authorization: `Bearer ${authTokens.access_token}`,
          },
      });
      setEventTypesOptions(res.data);
  } catch (e) {
      console.log(e);
      if (e.response.status === 401 && !isRefreshingToken) {
          await setIsRefreshingToken(true); // Set flag to prevent recursion
          await updateToken();
          await setIsRefreshingToken(false); // Reset flag after refresh
          await getEventTypesOptions();
      } else if (e.response.data.detail) {
          setGeneralError([e.response.status, e.response.data.detail]);
      } else if (e.response.data.code) {
          setGeneralError([e.response.status, e.response.data.code]);
      } else if (e.response.data.non_filed_errors) {
          setGeneralError([
              e.response.status,
              e.response.data.non_filed_errors[0],
          ]);
      }
  }
};

    useEffect(() => {
        const fetchData = async () => {
          setLoading(true);
            // await getEventsData(eventsDates[0], eventsDates[1])
            await getModuleOptions();
            await getClassOptions();
            await getEventTypesOptions();
          // setLoading(false);
        };
        if (!loading && !isRefreshingToken || true) {
            fetchData();
        }
        const body = document.getElementsByClassName("App")[0]
        body.classList.remove("lock")
    }, []);

    useEffect(() => {
      setLoading(true);
      getEventsData(eventsDates[0], eventsDates[1], eventsDates[2])
      setLoading(false);
    }, [eventsDates])


    return (
        <div>
            <MyToast error={generalError} closeAlert={closeAlert} />
            <Navbar />
            <div className="main">
            {loading ? (
                    <MyLoader />
                ) : (
                  <>
                <div className="mobile-calendar-navbar">
                  <div className="mobile-header-navigation">
                    {calendarType === 0 ? <DayManager /> : null}
                    {calendarType === 1 ? <WeekManager /> : null}
                    {calendarType === 2 ? <MonthManager /> : null}
                  </div>
                  <div className="mobile-header-today">
                    <i className="material-icons today-icon" onClick={handleToday}>today</i>
                    <i className='material-icons filters-icon' onClick={openFilters}>tune</i>
                  </div>
                </div>
                <div className="calendar-page-block">
                    <div className="calendar-block narrow" ref={calendarRef}>
                        <div className="calendar-body-block">
                            {calendarType === 0 ? <DayCalendar setEventsDates={setEventsDates} eventsDates={eventsDates} makeDetailed={makeDetailed} setEventToDetail={setEventToDetail} weekClick={weekClick} monthClick={monthClick}/> : null}
                            {calendarType === 1 ? <WeekCalendar setEventsDates={setEventsDates} eventsDates={eventsDates} func={makeDetailed} setCalendarType={setCalendarType} dayClick={dayClick} makeDetailed={makeDetailed} setEventToDetail={setEventToDetail}/> : null}
                            {calendarType === 2 ? <MonthCalendar setEventsDates={setEventsDates} eventsDates={eventsDates} setCalendarType={setCalendarType} dayClick={dayClick} weekClick={weekClick} makeDetailed={makeDetailed} setEventToDetail={setEventToDetail}/> : null}
                        </div>
                    </div>
                    <div className="filters-block">
                      <div className="calendar-type-chosing">
                            <MyButton onClick={dayClick} innerRef={dayRef}>День</MyButton>
                            <MyButton onClick={weekClick} innerRef={weekRef}>Неделя</MyButton>
                            <MyButton onClick={monthClick} innerRef={monthRef}>Месяц</MyButton>
                            <i className="material-icons today-icon" onClick={handleToday}>today</i>
                      </div>
                      <div className="calendar-management">
                            {calendarType === 0 ? <DayManager /> : null}
                            {calendarType === 1 ? <WeekManager /> : null}
                            {calendarType === 2 ? <MonthManager /> : null}
                      </div>
                      <CalendarFilters setEventsDates={setEventsDates} eventsDates={eventsDates} closeFilters={closeFilters} moduleOptions={moduleOptions} classOptions={classOptions} eventTypesOptions={eventTypesOptions}/>
                    </div>
                    <div className="filters-block-mobile closed" ref={filtersRef} key={moduleOptions}>
                        <CalendarFilters setEventsDates={setEventsDates} eventsDates={eventsDates} closeFilters={closeFilters} moduleOptions={moduleOptions} classOptions={classOptions} eventTypesOptions={eventTypesOptions}/>
                    </div>
                    <div className="detailed-event-block closed" ref={detailedRef}>
                        <DetailedEvent closeEvent={closeDetailed} event={eventToDetail} isDetailed={isDetailed}/>
                    </div>
                </div>
                </>
            )}
            </div>
        </div>
    );
}

export default CalendarPage;